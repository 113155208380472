<template>
	<div class="flex mx-auto grow h-full">
		<div class="flex justify-center w-9/12 mx-auto bg-green_lite">
			<div class="py-10 text-gray-600 space-y-5 text-center">
				<h3 class="text-3xl font-semibold text-gray-70">Phone</h3>
				<div v-for="(item, index) in downloadList" :key="index" class="bg-green-600 rounded-full py-4" style="width: 300px">
					<router-link :to="{ name: item.componentName }" class="font-semibold px-10 text-white">{{ item.name }}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Download',

	data() {
		return {
			downloadList: [
				{
					name: 'Android',
					componentName: 'howDoIInstallOnAndroidMobileTablet'
				},
				{
					name: 'iOS',
					componentName: 'installationInstructionsIPhoneIPad'
				},
				{
					name: 'XCiptv',
					componentName: 'xCiptvInstallationInstructionMultiScreen'
				},
				{
					name: 'AndroidBox',
					componentName: 'howDoIInstallOnAndroidBox'
				},
				{
					name: 'Tivimate',
					componentName: 'tivimateInstallationInstructions'
				},
				{
					name: 'Kodi',
					componentName: 'howDoIAddToKodi'
				},
				{
					name: 'Laptop',
					componentName: 'pcLaptopPcMacbookWebInterface'
				},
				{
					name: 'Nvidia-Shield/Xiomi-MiBox',
					componentName: 'androidTvNvidiaShieldXiomiMiBoxSonyAndroidTv'
				},
				{
					name: 'FireTV / Firestick',
					componentName: 'fireAmazonFireTvFireStickFireTv4K'
				}
			]
		};
	}
};
</script>

<style scoped></style>
